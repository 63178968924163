import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const state = {
  isDarkMode: false
}

const mutations = {
  CHANGE_MODE: (state, value) => {
    state.isDarkMode = value
  }
}

const actions = {
  changeMode: ({ commit }, value) => {
    commit('CHANGE_MODE', value)
  }
} 

export default new Vuex.Store({
  state,
  mutations,
  actions,
  modules: {
  }
})
